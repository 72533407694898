export default defineNuxtRouteMiddleware(async (to) => {
  const { getSession, token, session } = useAuth()

  if (to.meta.unauthenticated) {
    return
  }

  // Redirect if not logged in
  if (!token.value) {
    return navigateTo("/")
  }

  if (!session.value) {
    try {
      await getSession()
    } catch {
      return navigateTo("/logout")
    }
  }
})
