
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexlS43DWPcwNQEqm_4529c_M0V7y8CCgvaVx39VdnM7h9Y0Meta } from "/opt/buildhome/repo/app/admin/pages/team/index.vue?macro=true";
import { default as indexuyC0o6mlcjjjIFyWwqwXkDlgFsHk4lNv4_EpQ__45iGycMeta } from "/opt/buildhome/repo/app/admin/pages/team/groups/[id]/index.vue?macro=true";
import { default as settingsA6OqoCe4KakUY1zHdeEZpwI3_45pWau06bADW_EPiLmuQMeta } from "/opt/buildhome/repo/app/admin/pages/team/groups/[id]/settings.vue?macro=true";
import { default as _91id_93kksQmSmNmXmOYbMvJFR7wc5qOWi2JlRJ8YrPG5UpMOgMeta } from "/opt/buildhome/repo/app/admin/pages/team/groups/[id].vue?macro=true";
import { default as indexhpuzU7q7WcUz1cdWbLK1wZty46M3QchlBdWoJk8poZ4Meta } from "/opt/buildhome/repo/app/admin/pages/team/groups/index.vue?macro=true";
import { default as indexSPhWs6pykXmmOf1ZBBj1qa1S0NmEu076vRzDh_45XFlwAMeta } from "/opt/buildhome/repo/app/admin/pages/team/employees/[id]/index.vue?macro=true";
import { default as groupsujQQfJfvM6RIgQcWtFNdjLTG_fgKwePbMClv_I6QbsUMeta } from "/opt/buildhome/repo/app/admin/pages/team/employees/[id]/groups.vue?macro=true";
import { default as breachesFt6_4503B3rUUXh73xTLLHQtqvf6V6glOlugpheJAmIHwMeta } from "/opt/buildhome/repo/app/admin/pages/team/employees/[id]/breaches.vue?macro=true";
import { default as simulationsf2u9Nj2wpCki0U9KwHfteyPaMEa10o4Y7FRR23mnV8YMeta } from "/opt/buildhome/repo/app/admin/pages/team/employees/[id]/simulations.vue?macro=true";
import { default as _91id_93Zxa61IWF8L46Ostha_Avh2ZkfM4t0vjLMlShDuGkuMsMeta } from "/opt/buildhome/repo/app/admin/pages/team/employees/[id].vue?macro=true";
import { default as teamIGHu6qM_iQyZZdRDi_3lNW0cb6Zt4lrSYWU3WSTubzEMeta } from "/opt/buildhome/repo/app/admin/pages/team.vue?macro=true";
import { default as indexHvUQE7z94wVS0VfGWa4CX8rF0x2TuzJ4rZmVanjCTqYMeta } from "/opt/buildhome/repo/app/admin/pages/index.vue?macro=true";
import { default as logoutZ2HxytiaI5Kg83NvAMS3vkpBgBNrpQHT0hWolxQ8mzgMeta } from "/opt/buildhome/repo/app/admin/pages/logout.vue?macro=true";
import { default as index0O7zS0HlJrx_FyImenUBKUIbsO53EqmmJ_IEJvW6buoMeta } from "/opt/buildhome/repo/app/admin/pages/threats/index.vue?macro=true";
import { default as index7Jbf4zeF1fk3J02aCtAjMMK_zFMyE4MjheXi6kmh1z8Meta } from "/opt/buildhome/repo/app/admin/pages/threats/[id]/index.vue?macro=true";
import { default as threatszWN_45ikxFuQilJrLvCqp5T__g3ZxPrN6b4UKgqvTR8o0Meta } from "/opt/buildhome/repo/app/admin/pages/threats.vue?macro=true";
import { default as _91id_93eB23DaXchJommg26X7A4UZZi5IGsiv0w6Ua_J50RT8oMeta } from "/opt/buildhome/repo/app/admin/pages/breaches/[id].vue?macro=true";
import { default as index788WU3yCFmLeAg7CqDNpyYM5vr4vrWQYklYQkbukIbcMeta } from "/opt/buildhome/repo/app/admin/pages/breaches/index.vue?macro=true";
import { default as breachesJZivbH0U1oz2j50mO6QUvZVInXtV_7kNQu6DdBclFlQMeta } from "/opt/buildhome/repo/app/admin/pages/breaches.vue?macro=true";
import { default as riskyadtbj_45ziFTjZS9bDUR7EVfm5IwxrsIynV3fcZrzOFUMeta } from "/opt/buildhome/repo/app/admin/pages/settings/risk.vue?macro=true";
import { default as indexngrTSRYOrAfN_jwzvyZXUgjyI5SGPHaid6V6nRQt_SYMeta } from "/opt/buildhome/repo/app/admin/pages/settings/index.vue?macro=true";
import { default as exportb8T6rLQz55dDQ1b_45F7ohPuWf5CryFtHMCFzLWVDUPuEMeta } from "/opt/buildhome/repo/app/admin/pages/settings/export.vue?macro=true";
import { default as importldPVWQ5D9HRafP_yXV0ICl8_45Mi83vBgTnK5xFPiN3LIMeta } from "/opt/buildhome/repo/app/admin/pages/settings/import.vue?macro=true";
import { default as threats9AB8NwbB50_45qxIMMlWv2khlEPnfmGDIJ8OXEJu5OytEMeta } from "/opt/buildhome/repo/app/admin/pages/settings/threats.vue?macro=true";
import { default as breachesg7yf6M4_0CyXICRuBgzStt5a7E2cQY26BhsnRTJ5OmcMeta } from "/opt/buildhome/repo/app/admin/pages/settings/breaches.vue?macro=true";
import { default as simulations6B0v2bKR16Y107AmjUSKsUW9plGyrlVQJ9CiT1BWS_QMeta } from "/opt/buildhome/repo/app/admin/pages/settings/simulations.vue?macro=true";
import { default as indexU5W8hBpzEC_45ygEenZKh2Bn5JmR8KaCdUBT5AePdsSOQMeta } from "/opt/buildhome/repo/app/admin/pages/settings/integrations/index.vue?macro=true";
import { default as indexluvqFldZmmzgp0YRbysGWA5nuEmuKt60ajW64iWpjFEMeta } from "/opt/buildhome/repo/app/admin/pages/settings/integrations/[id]/index.vue?macro=true";
import { default as settingsX4AtKKNqTKc41dIzajYb4HSohuFbKSWk4d7a89M8WfQMeta } from "/opt/buildhome/repo/app/admin/pages/settings/integrations/[id]/settings.vue?macro=true";
import { default as integrationskIvmHwHoFDKc_bGXFalFRJpv2XDxCvpVz71Us7FIrtMMeta } from "/opt/buildhome/repo/app/admin/pages/settings/integrations.vue?macro=true";
import { default as settingsOU02WWYhTQI_45nKLtupI_30RHFIRpxBPx0MEvnwU9GGoMeta } from "/opt/buildhome/repo/app/admin/pages/settings.vue?macro=true";
import { default as indexJBTj_45V9iMPw6careTRubnTPXlr3NXl2Jowr1Ss1_4508sMeta } from "/opt/buildhome/repo/app/admin/pages/dashboard/index.vue?macro=true";
import { default as threatsLcITGFHQGplP2rnlL6hhfFmSx6O_wKZDDvXYWD4FjDsMeta } from "/opt/buildhome/repo/app/admin/pages/dashboard/threats.vue?macro=true";
import { default as trainingmwzEnBdfaaKf8A8sXOGlz1JgAfKpXkZfH8OUrvOblh4Meta } from "/opt/buildhome/repo/app/admin/pages/dashboard/training.vue?macro=true";
import { default as resiliencebwBlH_iYDjITlf2jvUm2aXANWXl5DyHqdYkkQeFL30YMeta } from "/opt/buildhome/repo/app/admin/pages/dashboard/resilience.vue?macro=true";
import { default as simulationsnrK_xL7hyX8w16O_456Tx1jqdnvmMJvnwF8fQlaMF3aT8Meta } from "/opt/buildhome/repo/app/admin/pages/dashboard/simulations.vue?macro=true";
import { default as dashboardJMGn8JG4NYEtdqfpLpwfv3kjzeVcN_MhbA5RzNLkv9UMeta } from "/opt/buildhome/repo/app/admin/pages/dashboard.vue?macro=true";
import { default as indexRfkwp24TQXCFIBuIEAfqiJ_3nHzfVhHZPbZJMAPkd5UMeta } from "/opt/buildhome/repo/app/admin/pages/elearning/index.vue?macro=true";
import { default as courses3_bksggIf18TUt3ZUvGue8ozUNtPKE2TYXCkQGVXv_45IMeta } from "/opt/buildhome/repo/app/admin/pages/elearning/courses.vue?macro=true";
import { default as deadlinesNuIVlOgIhnbJg0wk_pcLVVThbE7y6giNCx_CWc3LQyUMeta } from "/opt/buildhome/repo/app/admin/pages/elearning/deadlines.vue?macro=true";
import { default as _91id_93uvDVfwNBIRfx8YkrpY8gsJsTtWUb3YjB0TzxrLtZ0cQMeta } from "/opt/buildhome/repo/app/admin/pages/elearning/[type]/[id].vue?macro=true";
import { default as elearningrYlEIQPz1SIGK8tRVE9Jt6affysuMJ8HRC5Z2l_454TZYMeta } from "/opt/buildhome/repo/app/admin/pages/elearning.vue?macro=true";
import { default as indexc8qDbp9j9YEk13qATc5hIjSc9WkeznYDU2EdFsmSj28Meta } from "/opt/buildhome/repo/app/admin/pages/automation/index.vue?macro=true";
import { default as index9w97AIi0uSSw6XJWAEQXQCg7ZanVxyrTkc4F0yoojC8Meta } from "/opt/buildhome/repo/app/admin/pages/automation/[id]/edit/index.vue?macro=true";
import { default as settingsjfxNJHKKRaBzomuXSWK9hpG4p_nqmC8rvsN_45JrRDP4kMeta } from "/opt/buildhome/repo/app/admin/pages/automation/[id]/edit/settings.vue?macro=true";
import { default as editANWqO5L4v9zMa6CLqQFfpmsU1hfqTpsuRUj4QBurhc4Meta } from "/opt/buildhome/repo/app/admin/pages/automation/[id]/edit.vue?macro=true";
import { default as indexhUekZPJPAXxxo_454vplPxCc_45X7zohjymprF0j_45fqgzX0Meta } from "/opt/buildhome/repo/app/admin/pages/automation/[id]/index.vue?macro=true";
import { default as _91actionId_93lMtzTizbCgNfN7bqGyWpGOqIvjb_7bwkekSnrSck6HMMeta } from "/opt/buildhome/repo/app/admin/pages/automation/[id]/action/[actionId].vue?macro=true";
import { default as automation4WPLryexFrf6BP9rOsC8M0mNjuDCl_457Vcdx0y2NHcYAMeta } from "/opt/buildhome/repo/app/admin/pages/automation.vue?macro=true";
import { default as error_45testFPdcKIphIB2YjU7eUpdhPjvUU8tD_451TfOODErh0hGOYMeta } from "/opt/buildhome/repo/app/admin/pages/error-test.vue?macro=true";
import { default as quizL4pQ88cM3Onohv7SRCOaljeGaTHVrUkhpvYvpBatJXAMeta } from "/opt/buildhome/repo/app/admin/pages/onboarding/quiz.vue?macro=true";
import { default as indexomYAfIKdSkKt8dNWammHbeLo1CmIqOibf6OYtfQZs3cMeta } from "/opt/buildhome/repo/app/admin/pages/onboarding/index.vue?macro=true";
import { default as finished0pn0m642lJnpgorz_45bJrcZ8B3PtlxBX_45qLIPZw3jYxIMeta } from "/opt/buildhome/repo/app/admin/pages/onboarding/finished.vue?macro=true";
import { default as risk_45levelyn7G7CAczIBObQpYbdhxzmycR7lvyjJGgf6SnuO_456oMMeta } from "/opt/buildhome/repo/app/admin/pages/onboarding/risk-level.vue?macro=true";
import { default as setup_45googleHw4qYgiQMisriM1vuAN2gry_45XGZ6uurJlxtAAOTFotMMeta } from "/opt/buildhome/repo/app/admin/pages/onboarding/setup-google.vue?macro=true";
import { default as setup_45microsoftEXQpWBnGOQyMKMUpu6PqjsYdl5TGhPIbUMb_hSH7OV8Meta } from "/opt/buildhome/repo/app/admin/pages/onboarding/setup-microsoft.vue?macro=true";
import { default as indexDCDPyDVEbDAcpemPFuaqE0UmKoiRyGq3Qlv6lj9mKjsMeta } from "/opt/buildhome/repo/app/admin/pages/onboarding/integration/index.vue?macro=true";
import { default as _91provider_93ElV5UvmN2Y9cGORZEVNky_g6SbowBKYGLIi6H57CdYAMeta } from "/opt/buildhome/repo/app/admin/pages/onboarding/integration/[provider].vue?macro=true";
import { default as onboardinggvRqjrYAxB_OTsb8EbSO2MSnXPTD8L5OezHWJ21aa40Meta } from "/opt/buildhome/repo/app/admin/pages/onboarding.vue?macro=true";
import { default as impersonateERHSBkPND6o87po8B1nfa_45jSeHmK5ze4JBvRgrv7if8Meta } from "/opt/buildhome/repo/app/admin/pages/impersonate.vue?macro=true";
import { default as _91id_939gPlIIOZsYqCfcD4DkMppe7ZzZk6CYfVJFuYBDQeCuQMeta } from "/opt/buildhome/repo/app/admin/pages/invite/[id].vue?macro=true";
import { default as indexLpnbvk0rJDYz_45kOXYzhsW0uhe3Qiegya4883Zil9_9wMeta } from "/opt/buildhome/repo/app/admin/pages/simulations/index.vue?macro=true";
import { default as indexdE_45t_fVXeqEtXbcN5_OD0yND6Zjo1RQ5TxBpuyZN3nUMeta } from "/opt/buildhome/repo/app/admin/pages/simulations/[id]/index.vue?macro=true";
import { default as settingsIkG2SM9TYUybKDOAfHUvk0dSwCrtGhfO8g8hQneB_45bsMeta } from "/opt/buildhome/repo/app/admin/pages/simulations/[id]/settings.vue?macro=true";
import { default as scheduledYmTKi7983qxx5IB7O1Tl3g9p8wBDeuVpS91rtTBiN7IMeta } from "/opt/buildhome/repo/app/admin/pages/simulations/[id]/scheduled.vue?macro=true";
import { default as simulationsZhqEvgL6tBAem0vLl7TO0yZ9_ep82uvoWUjKAKGygVYMeta } from "/opt/buildhome/repo/app/admin/pages/simulations.vue?macro=true";
import { default as indexxcxCKZOwERDGuv7ff04_BEAGuNnbADT0kS2qmQX3x44Meta } from "/opt/buildhome/repo/app/admin/pages/invite/index.vue?macro=true";
import { default as index_45DM5GFxxCAiu07lSCFxeSxXeYU827WUHrqxhqu_455xeYMeta } from "/opt/buildhome/repo/app/admin/pages/policies/index.vue?macro=true";
import { default as forgot_45passwordGMTacNgCMfXxI4ElipkziaKGN6f_4570_2dARmc83VJWAMeta } from "/opt/buildhome/repo/app/admin/pages/forgot-password.vue?macro=true";
import { default as _91id_933g9Dtp4iQDJVTMa4zBmoBSU81ObOuDtKC65T0xzXCXgMeta } from "/opt/buildhome/repo/app/admin/pages/validate/domain/[id].vue?macro=true";
import { default as _91provider_937nefL31gVbhZSonDSOxO0iWCB_8BBeO_XootKS8H2c8Meta } from "/opt/buildhome/repo/app/admin/pages/integration/[provider].vue?macro=true";
import { default as _91token_93y0sR2zFrgPwGFuxNaWV91AoQJDN2vBhGHz9uhyuGi2wMeta } from "/opt/buildhome/repo/app/admin/pages/reset-password/[token].vue?macro=true";
export default [
  {
    name: teamIGHu6qM_iQyZZdRDi_3lNW0cb6Zt4lrSYWU3WSTubzEMeta?.name,
    path: "/team",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team.vue"),
    children: [
  {
    name: "team",
    path: "",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/index.vue")
  },
  {
    name: _91id_93kksQmSmNmXmOYbMvJFR7wc5qOWi2JlRJ8YrPG5UpMOgMeta?.name,
    path: "groups/:id()",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/groups/[id].vue"),
    children: [
  {
    name: "team-groups-id",
    path: "",
    meta: indexuyC0o6mlcjjjIFyWwqwXkDlgFsHk4lNv4_EpQ__45iGycMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/groups/[id]/index.vue")
  },
  {
    name: "team-groups-id-settings",
    path: "settings",
    meta: settingsA6OqoCe4KakUY1zHdeEZpwI3_45pWau06bADW_EPiLmuQMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/groups/[id]/settings.vue")
  }
]
  },
  {
    name: "team-groups",
    path: "groups",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/groups/index.vue")
  },
  {
    name: _91id_93Zxa61IWF8L46Ostha_Avh2ZkfM4t0vjLMlShDuGkuMsMeta?.name,
    path: "employees/:id()",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/employees/[id].vue"),
    children: [
  {
    name: "team-employees-id",
    path: "",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/employees/[id]/index.vue")
  },
  {
    name: "team-employees-id-groups",
    path: "groups",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/employees/[id]/groups.vue")
  },
  {
    name: "team-employees-id-breaches",
    path: "breaches",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/employees/[id]/breaches.vue")
  },
  {
    name: "team-employees-id-simulations",
    path: "simulations",
    component: () => import("/opt/buildhome/repo/app/admin/pages/team/employees/[id]/simulations.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexHvUQE7z94wVS0VfGWa4CX8rF0x2TuzJ4rZmVanjCTqYMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutZ2HxytiaI5Kg83NvAMS3vkpBgBNrpQHT0hWolxQ8mzgMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/logout.vue")
  },
  {
    name: threatszWN_45ikxFuQilJrLvCqp5T__g3ZxPrN6b4UKgqvTR8o0Meta?.name,
    path: "/threats",
    component: () => import("/opt/buildhome/repo/app/admin/pages/threats.vue"),
    children: [
  {
    name: "threats",
    path: "",
    component: () => import("/opt/buildhome/repo/app/admin/pages/threats/index.vue")
  },
  {
    name: "threats-id",
    path: ":id()",
    meta: index7Jbf4zeF1fk3J02aCtAjMMK_zFMyE4MjheXi6kmh1z8Meta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/threats/[id]/index.vue")
  }
]
  },
  {
    name: breachesJZivbH0U1oz2j50mO6QUvZVInXtV_7kNQu6DdBclFlQMeta?.name,
    path: "/breaches",
    component: () => import("/opt/buildhome/repo/app/admin/pages/breaches.vue"),
    children: [
  {
    name: "breaches-id",
    path: ":id()",
    meta: _91id_93eB23DaXchJommg26X7A4UZZi5IGsiv0w6Ua_J50RT8oMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/breaches/[id].vue")
  },
  {
    name: "breaches",
    path: "",
    component: () => import("/opt/buildhome/repo/app/admin/pages/breaches/index.vue")
  }
]
  },
  {
    name: settingsOU02WWYhTQI_45nKLtupI_30RHFIRpxBPx0MEvnwU9GGoMeta?.name,
    path: "/settings",
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings.vue"),
    children: [
  {
    name: "settings-risk",
    path: "risk",
    meta: riskyadtbj_45ziFTjZS9bDUR7EVfm5IwxrsIynV3fcZrzOFUMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/risk.vue")
  },
  {
    name: "settings",
    path: "",
    meta: indexngrTSRYOrAfN_jwzvyZXUgjyI5SGPHaid6V6nRQt_SYMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/index.vue")
  },
  {
    name: "settings-export",
    path: "export",
    meta: exportb8T6rLQz55dDQ1b_45F7ohPuWf5CryFtHMCFzLWVDUPuEMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/export.vue")
  },
  {
    name: "settings-import",
    path: "import",
    meta: importldPVWQ5D9HRafP_yXV0ICl8_45Mi83vBgTnK5xFPiN3LIMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/import.vue")
  },
  {
    name: "settings-threats",
    path: "threats",
    meta: threats9AB8NwbB50_45qxIMMlWv2khlEPnfmGDIJ8OXEJu5OytEMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/threats.vue")
  },
  {
    name: "settings-breaches",
    path: "breaches",
    meta: breachesg7yf6M4_0CyXICRuBgzStt5a7E2cQY26BhsnRTJ5OmcMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/breaches.vue")
  },
  {
    name: "settings-simulations",
    path: "simulations",
    meta: simulations6B0v2bKR16Y107AmjUSKsUW9plGyrlVQJ9CiT1BWS_QMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/simulations.vue")
  },
  {
    name: integrationskIvmHwHoFDKc_bGXFalFRJpv2XDxCvpVz71Us7FIrtMMeta?.name,
    path: "integrations",
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/integrations.vue"),
    children: [
  {
    name: "settings-integrations",
    path: "",
    meta: indexU5W8hBpzEC_45ygEenZKh2Bn5JmR8KaCdUBT5AePdsSOQMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/integrations/index.vue")
  },
  {
    name: "settings-integrations-id",
    path: ":id()",
    meta: indexluvqFldZmmzgp0YRbysGWA5nuEmuKt60ajW64iWpjFEMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/integrations/[id]/index.vue")
  },
  {
    name: "settings-integrations-id-settings",
    path: ":id()/settings",
    meta: settingsX4AtKKNqTKc41dIzajYb4HSohuFbKSWk4d7a89M8WfQMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/settings/integrations/[id]/settings.vue")
  }
]
  }
]
  },
  {
    name: dashboardJMGn8JG4NYEtdqfpLpwfv3kjzeVcN_MhbA5RzNLkv9UMeta?.name,
    path: "/dashboard",
    component: () => import("/opt/buildhome/repo/app/admin/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard",
    path: "",
    component: () => import("/opt/buildhome/repo/app/admin/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-threats",
    path: "threats",
    component: () => import("/opt/buildhome/repo/app/admin/pages/dashboard/threats.vue")
  },
  {
    name: "dashboard-training",
    path: "training",
    component: () => import("/opt/buildhome/repo/app/admin/pages/dashboard/training.vue")
  },
  {
    name: "dashboard-resilience",
    path: "resilience",
    component: () => import("/opt/buildhome/repo/app/admin/pages/dashboard/resilience.vue")
  },
  {
    name: "dashboard-simulations",
    path: "simulations",
    component: () => import("/opt/buildhome/repo/app/admin/pages/dashboard/simulations.vue")
  }
]
  },
  {
    name: elearningrYlEIQPz1SIGK8tRVE9Jt6affysuMJ8HRC5Z2l_454TZYMeta?.name,
    path: "/elearning",
    component: () => import("/opt/buildhome/repo/app/admin/pages/elearning.vue"),
    children: [
  {
    name: "elearning",
    path: "",
    component: () => import("/opt/buildhome/repo/app/admin/pages/elearning/index.vue")
  },
  {
    name: "elearning-courses",
    path: "courses",
    component: () => import("/opt/buildhome/repo/app/admin/pages/elearning/courses.vue")
  },
  {
    name: "elearning-deadlines",
    path: "deadlines",
    component: () => import("/opt/buildhome/repo/app/admin/pages/elearning/deadlines.vue")
  },
  {
    name: "elearning-type-id",
    path: ":type()/:id()",
    meta: _91id_93uvDVfwNBIRfx8YkrpY8gsJsTtWUb3YjB0TzxrLtZ0cQMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/elearning/[type]/[id].vue")
  }
]
  },
  {
    name: automation4WPLryexFrf6BP9rOsC8M0mNjuDCl_457Vcdx0y2NHcYAMeta?.name,
    path: "/automation",
    component: () => import("/opt/buildhome/repo/app/admin/pages/automation.vue"),
    children: [
  {
    name: "automation",
    path: "",
    component: () => import("/opt/buildhome/repo/app/admin/pages/automation/index.vue")
  },
  {
    name: editANWqO5L4v9zMa6CLqQFfpmsU1hfqTpsuRUj4QBurhc4Meta?.name,
    path: ":id()/edit",
    component: () => import("/opt/buildhome/repo/app/admin/pages/automation/[id]/edit.vue"),
    children: [
  {
    name: "automation-id-edit",
    path: "",
    meta: index9w97AIi0uSSw6XJWAEQXQCg7ZanVxyrTkc4F0yoojC8Meta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/automation/[id]/edit/index.vue")
  },
  {
    name: "automation-id-edit-settings",
    path: "settings",
    meta: settingsjfxNJHKKRaBzomuXSWK9hpG4p_nqmC8rvsN_45JrRDP4kMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/automation/[id]/edit/settings.vue")
  }
]
  },
  {
    name: "automation-id",
    path: ":id()",
    meta: indexhUekZPJPAXxxo_454vplPxCc_45X7zohjymprF0j_45fqgzX0Meta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/automation/[id]/index.vue")
  },
  {
    name: "automation-id-action-actionId",
    path: ":id()/action/:actionId()",
    meta: _91actionId_93lMtzTizbCgNfN7bqGyWpGOqIvjb_7bwkekSnrSck6HMMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/automation/[id]/action/[actionId].vue")
  }
]
  },
  {
    name: "error-test",
    path: "/error-test",
    meta: error_45testFPdcKIphIB2YjU7eUpdhPjvUU8tD_451TfOODErh0hGOYMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/error-test.vue")
  },
  {
    name: onboardinggvRqjrYAxB_OTsb8EbSO2MSnXPTD8L5OezHWJ21aa40Meta?.name,
    path: "/onboarding",
    meta: onboardinggvRqjrYAxB_OTsb8EbSO2MSnXPTD8L5OezHWJ21aa40Meta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding.vue"),
    children: [
  {
    name: "onboarding-quiz",
    path: "quiz",
    meta: quizL4pQ88cM3Onohv7SRCOaljeGaTHVrUkhpvYvpBatJXAMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding/quiz.vue")
  },
  {
    name: "onboarding",
    path: "",
    meta: indexomYAfIKdSkKt8dNWammHbeLo1CmIqOibf6OYtfQZs3cMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding/index.vue")
  },
  {
    name: "onboarding-finished",
    path: "finished",
    meta: finished0pn0m642lJnpgorz_45bJrcZ8B3PtlxBX_45qLIPZw3jYxIMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding/finished.vue")
  },
  {
    name: "onboarding-risk-level",
    path: "risk-level",
    meta: risk_45levelyn7G7CAczIBObQpYbdhxzmycR7lvyjJGgf6SnuO_456oMMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding/risk-level.vue")
  },
  {
    name: "onboarding-setup-google",
    path: "setup-google",
    meta: setup_45googleHw4qYgiQMisriM1vuAN2gry_45XGZ6uurJlxtAAOTFotMMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding/setup-google.vue")
  },
  {
    name: "onboarding-setup-microsoft",
    path: "setup-microsoft",
    meta: setup_45microsoftEXQpWBnGOQyMKMUpu6PqjsYdl5TGhPIbUMb_hSH7OV8Meta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding/setup-microsoft.vue")
  },
  {
    name: "onboarding-integration",
    path: "integration",
    meta: indexDCDPyDVEbDAcpemPFuaqE0UmKoiRyGq3Qlv6lj9mKjsMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding/integration/index.vue")
  },
  {
    name: "onboarding-integration-provider",
    path: "integration/:provider()",
    meta: _91provider_93ElV5UvmN2Y9cGORZEVNky_g6SbowBKYGLIi6H57CdYAMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/onboarding/integration/[provider].vue")
  }
]
  },
  {
    name: "impersonate",
    path: "/impersonate",
    meta: impersonateERHSBkPND6o87po8B1nfa_45jSeHmK5ze4JBvRgrv7if8Meta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/impersonate.vue")
  },
  {
    name: "invite-id",
    path: "/invite/:id()",
    meta: _91id_939gPlIIOZsYqCfcD4DkMppe7ZzZk6CYfVJFuYBDQeCuQMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/invite/[id].vue")
  },
  {
    name: simulationsZhqEvgL6tBAem0vLl7TO0yZ9_ep82uvoWUjKAKGygVYMeta?.name,
    path: "/simulations",
    component: () => import("/opt/buildhome/repo/app/admin/pages/simulations.vue"),
    children: [
  {
    name: "simulations",
    path: "",
    component: () => import("/opt/buildhome/repo/app/admin/pages/simulations/index.vue")
  },
  {
    name: "simulations-id",
    path: ":id()",
    meta: indexdE_45t_fVXeqEtXbcN5_OD0yND6Zjo1RQ5TxBpuyZN3nUMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/simulations/[id]/index.vue")
  },
  {
    name: "simulations-id-settings",
    path: ":id()/settings",
    meta: settingsIkG2SM9TYUybKDOAfHUvk0dSwCrtGhfO8g8hQneB_45bsMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/simulations/[id]/settings.vue")
  },
  {
    name: "simulations-id-scheduled",
    path: ":id()/scheduled",
    meta: scheduledYmTKi7983qxx5IB7O1Tl3g9p8wBDeuVpS91rtTBiN7IMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/simulations/[id]/scheduled.vue")
  }
]
  },
  {
    name: "invite",
    path: "/invite",
    meta: indexxcxCKZOwERDGuv7ff04_BEAGuNnbADT0kS2qmQX3x44Meta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/invite/index.vue")
  },
  {
    name: "policies",
    path: "/policies",
    component: () => import("/opt/buildhome/repo/app/admin/pages/policies/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordGMTacNgCMfXxI4ElipkziaKGN6f_4570_2dARmc83VJWAMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/forgot-password.vue")
  },
  {
    name: "validate-domain-id",
    path: "/validate/domain/:id()",
    meta: _91id_933g9Dtp4iQDJVTMa4zBmoBSU81ObOuDtKC65T0xzXCXgMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/validate/domain/[id].vue")
  },
  {
    name: "integration-provider",
    path: "/integration/:provider()",
    meta: _91provider_937nefL31gVbhZSonDSOxO0iWCB_8BBeO_XootKS8H2c8Meta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/integration/[provider].vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: _91token_93y0sR2zFrgPwGFuxNaWV91AoQJDN2vBhGHz9uhyuGi2wMeta || {},
    component: () => import("/opt/buildhome/repo/app/admin/pages/reset-password/[token].vue")
  }
]