import { captureException } from "@sentry/nuxt"

// Taken but modified from https://nuxt.com/docs/guide/recipes/custom-usefetch
export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const { devState } = useDeveloperTools()

  /** Fetches with authentication, proper csrf token and accept headers.
   * The API usually works without an accept header, but if the token is invalid it will return a 302 instead of 401. */
  const api = $fetch.create({
    baseURL: runtimeConfig.public.apiBase,
    credentials: import.meta.client ? "include" : undefined,
    async onRequest({ options }) {
      if (devState.value.loadingDelay > 0) {
        await new Promise((r) => setTimeout(r, devState.value.loadingDelay))
      }
      const csrf = useCookie("XSRF-TOKEN")
      const { token, workspaceId } = useAuth()
      if (import.meta.server) {
        // Cloudflare workers crashes if this is set on server side
        // See https://github.com/cloudflare/workers-sdk/issues/2514
        options.credentials = undefined
      }
      const headers = options.headers
      if (Array.isArray(headers)) {
        if (token.value) {
          headers.push(["Authorization", `Bearer ${token.value}`])
        }
        if (workspaceId.value) {
          headers.push(["X-Workspace", workspaceId.value])
        }
        if (csrf.value) {
          headers.push(["X-XSRF-TOKEN", csrf.value])
        }
        if (
          options.method != "get" &&
          !headers.some((header) => header[0].toLowerCase() === "accept")
        ) {
          headers.push(["Accept", "application/json"])
        }
      } else if (headers instanceof Headers) {
        if (token.value) {
          headers.set("Authorization", `Bearer ${token.value}`)
        }
        if (workspaceId.value) {
          headers.set("X-Workspace", workspaceId.value)
        }
        if (csrf.value) {
          headers.set("X-XSRF-TOKEN", csrf.value)
        }
        if (options.method != "get" && !headers.has("Accept")) {
          headers.set("Accept", "application/json")
        }
      }
    },
    async onResponseError(context) {
      const response = context.response
      const logoutResponseCodes = [401, 403]
      if (logoutResponseCodes.includes(response.status)) {
        await navigateTo("/logout")
      } else if (response.status != 419) {
        if (context.error) {
          captureException(context.error)
        } else {
          captureException(new Error(`${response.status}: Unknown fetch error`))
        }
      }
    },
  })

  /** Fetches with csrf token, without extra fluff. */
  const apiCsrfOnly = $fetch.create({
    baseURL: runtimeConfig.public.apiBase,
    credentials: import.meta.client ? "include" : undefined,
    onRequest({ options }) {
      const csrf = useCookie("XSRF-TOKEN")
      if (import.meta.server) {
        // Cloudflare workers crashes if this is set on server side
        // See https://github.com/cloudflare/workers-sdk/issues/2514
        options.credentials = undefined
      }
      const headers = options.headers
      if (Array.isArray(headers)) {
        if (csrf.value) {
          headers.push(["X-XSRF-TOKEN", csrf.value])
        }
        if (
          options.method != "get" &&
          !headers.some((header) => header[0].toLowerCase() === "accept")
        ) {
          headers.push(["Accept", "application/json"])
        }
      } else if (headers instanceof Headers) {
        if (csrf.value) {
          headers.set("X-XSRF-TOKEN", csrf.value)
        }
        if (options.method != "get" && !headers.has("Accept")) {
          headers.set("Accept", "application/json")
        }
      }
    },
    onResponseError(context) {
      const response = context.response
      if (response.status != 419) {
        if (context.error) {
          captureException(context.error)
        } else {
          captureException(new Error(`${response.status}: Unknown fetch error`))
        }
      }
    },
  })

  return {
    provide: {
      api,
      apiCsrfOnly,
    },
  }
})
