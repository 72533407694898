import type { Feature, Session } from "~/server/routes/admin/v1/auth/login"

export type SessionWithToken = Session & { token: string }

export const useAuth = () => {
  /** Contains information about logged in user and workspace */
  const session = useState<Session | undefined>()

  /** Token cookie, set upon login */
  const token = useCookie<string | undefined>("token")

  const workspaceId = useCookie<string | undefined>("workspace_id")

  const isImpersonating = useCookie<true | undefined>("impersonating")

  const { $apiCsrfOnly } = useNuxtApp()

  /** Logs in with provided credentials and sets `session` state and `token`
   * cookie if successful */
  async function login(email: string, password: string): Promise<Session> {
    const result = await $apiCsrfOnly<SessionWithToken>(
      "/admin/v1/auth/login",
      {
        baseURL: runtimeConfig.public.apiBase,
        method: "post",
        body: { email, password },
      },
    )
    session.value = result
    token.value = result.token
    workspaceId.value = result.workspace
    return result
  }

  const runtimeConfig = useRuntimeConfig()
  async function getSession(): Promise<Session | undefined> {
    if (!token.value || !workspaceId.value) {
      return
    }
    const result = await $apiCsrfOnly<Session>("/admin/v1/session", {
      baseURL: runtimeConfig.public.apiBase,
      headers: {
        Authorization: `Bearer ${token.value}`,
        "X-Workspace": workspaceId.value,
      },
    })
    session.value = result
    workspaceId.value = result.workspace
    return result
  }

  /** Clears auth cookies and state.
   * Does not redirect */
  function logout() {
    token.value = undefined
    workspaceId.value = undefined
    session.value = undefined
    isImpersonating.value = undefined
  }

  const hasFeature = (featureName: Feature): boolean => {
    return session.value?.features.includes(featureName) ?? false
  }

  const isManager = computed<boolean>(
    () => session.value?.user.role == "manager",
  )

  return {
    hasFeature,
    login,
    token,
    session,
    logout,
    getSession,
    workspaceId,
    isManager,
    isImpersonating,
  }
}
