import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/buildhome/repo/app/admin/middleware/auth.global.ts";
import role_45check_45global from "/opt/buildhome/repo/app/admin/middleware/role_check.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  role_45check_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}