<script setup lang="ts">
import type { SetupContext } from "vue"
import type { RouteLocationRaw } from "#vue-router"

const props = withDefaults(
  defineProps<
    {
      title?: string
      disabled?: boolean
      pressed?: boolean
      type?: string
      loading?: boolean
      variant?: FormButtonVariant
      size?: "xl" | "default" | "sm"
    } & (
      | {
          // used as a link
          to: string | RouteLocationRaw
          target?: "_self" | "_blank" | "_parent" | "_top" | "_unfencedTop"
          form?: undefined
        }
      | {
          // used to submit a form
          form?: string
          to?: undefined
          target?: undefined
        }
    )
  >(),
  {
    variant: "primary",
    size: "default",
    form: undefined,
    to: undefined,
    target: undefined,
    title: undefined,
    disabled: false,
    pressed: false,
    type: "button",
    loading: false,
  },
)

const emit = defineEmits(["click"])

// Fixes bug in vue with wrong types.
// See https://github.com/vuejs/language-tools/issues/5082#issuecomment-2563087929
const slots: SetupContext["slots"] = useSlots()
const hasContentInDefaultSlot = slots.default !== undefined
const hasContentInIconSlot = slots.icon !== undefined

const isLink = computed(() => !!props.to)

const tagName = computed(() => {
  if (props.to) {
    return resolveComponent("NuxtLink")
  }
  return "button"
})

const buttonType = computed(() => (isLink.value ? null : props.type))

const sizeClasses = computed(() => {
  if (props.variant == "text") {
    return ""
  }
  if (props.size === "xl") {
    return "px-5 py-3 text-base"
  }
  if (props.size === "sm") {
    return "px-3 py-1 text-sm"
  }
  return "px-4 py-2 text-sm"
})

const variantClasses = computed(() => {
  const baseClasses =
    "inline-flex cursor-pointer items-center justify-center rounded-lg border px-4 py-2 font-medium shadow-xs transition duration-150 ease-in-out focus:outline-hidden focus:ring-2 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-75"
  if (props.disabled) {
    return `${
      baseClasses
    } border-slate-200 text-slate-700 bg-slate-200 cursor-not-allowed`
  }
  if (props.variant === "secondary") {
    return `${
      baseClasses
    } border-slate-300 text-slate-800 bg-white hover:bg-slate-100 focus:ring-offset-slate-200 focus:ring-slate-400`
  }
  if (props.variant === "dark") {
    return `${
      baseClasses
    } cursor-pointer border-slate-700 text-slate-300 bg-slate-800 hover:bg-slate-700 hover:text-white focus:ring-offset-slate-500 focus:ring-slate-600`
  }
  if (props.variant === "black") {
    return `${
      baseClasses
    } cursor-pointer border-slate-700 text-white bg-slate-950 hover:bg-slate-700 hover:text-white focus:ring-offset-slate-500 focus:ring-slate-600`
  }
  if (props.variant === "warning") {
    return `${
      baseClasses
    } cursor-pointer border-orange-500 text-white bg-orange-400 hover:border-orange-600 hover:bg-orange-500 focus:ring-offset-orange-100 focus:ring-orange-500 px-4 py-2.5 text-sm`
  }
  if (props.variant == "text") {
    return "hover:underline inline-flex cursor-pointer items-center justify-center"
  }
  return `${
    baseClasses
  } border-transparent text-white bg-slate-600 hover:bg-slate-700 focus:ring-offset-slate-100 focus:ring-slate-400`
})

function onClick(event: Event) {
  emit("click", event)
}
</script>

<script type="module" lang="ts">
export type FormButtonVariant =
  | "primary"
  | "secondary"
  | "dark"
  | "text"
  | "black"
  | "warning"
</script>

<template>
  <component
    :is="tagName"
    :class="[
      {
        'is-pressed': pressed,
        'has-content': hasContentInDefaultSlot,
      },
      variantClasses,
      sizeClasses,
    ]"
    :target
    :disabled="disabled || loading"
    :title
    :type="buttonType"
    :to
    :form
    @click="onClick"
  >
    <svg
      v-if="loading"
      :class="{ 'mr-2': hasContentInDefaultSlot }"
      class="size-4 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <div
      v-if="hasContentInIconSlot && !loading"
      class="*:size-4"
      :class="{ 'mr-2': hasContentInDefaultSlot }"
    >
      <slot name="icon" />
    </div>
    <slot />
  </component>
</template>
