import revive_payload_client_3udzDft70aWv5whKWwO9ZS1DwZoLKijVruPoav2cGrs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ei7Kc5sgJhm_ZT6Ku3QFKglhh_np3tyCJ8x7h_oY1zc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ncv425wa6JVKOrpunRRYzdWAFGPi6j3V0E5sQIXIAws from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_9awFqeJfOxWWw9P9gGujmOhoruIPaxuOyIqhMnDZJso from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_crtsTcArahX_z_mIy4A7Wd8mH5QPtOBGvuhy9a5FzTw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__x4rjp3_mFzRiX8bUTRJ9V7nkPvr6qr623vfBzwfh0M from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ipyFGoV_cc2tKN5Ft76XgDCSH58ohXXoBt5M0Bihqeg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/app/admin/.nuxt/components.plugin.mjs";
import prefetch_client_fWxD_a37zqMHE4pQP2oj8LX09X_dzK1VQioQlxMSSQg from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.16.0_jiti@2.4.2__ior_n6k6tsp23dpitvy4dcgefai5be/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_lI2TwdvWlaucy2xLmAW4iRSp8oey_G5EUAq60bdPyDY from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.43.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.29.0_@opentelemetry+api@1._ihkpijubzsmc4lpg36m4ugqfzm/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/opt/buildhome/repo/app/admin/.nuxt/sentry-client-config.mjs";
import switch_locale_path_ssr_fnxa2RYAY_peo_MMkQ7_VG1NhrAK_PVtbTTHX6LELzw from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_uirknheqmrxxsqr7sdms2yk7xq/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_W8L_3FWZffxQXyYzjnU2y6T1XqXg4c9TA_N6fHHyIsc from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_uirknheqmrxxsqr7sdms2yk7xq/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_LsSaASFS02kZq87HHqjlArgumkYBqMEtIreF9ajiw4A from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_uirknheqmrxxsqr7sdms2yk7xq/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/opt/buildhome/repo/app/admin/plugins/api.ts";
import hasFeature_I9mRgyaoLrM3oQj4QrQUzBYhMbVNM2ZpgvIZP2OsjFY from "/opt/buildhome/repo/app/admin/plugins/hasFeature.ts";
import ssg_detect_2DbCXkmMHx4N4OEdBb6zRiYZPfVasIxCqYjroZKZU1Y from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.16.0_jiti@2.4.2__magicast@0.3.5_rollup@4_uirknheqmrxxsqr7sdms2yk7xq/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_3udzDft70aWv5whKWwO9ZS1DwZoLKijVruPoav2cGrs,
  unhead_Ei7Kc5sgJhm_ZT6Ku3QFKglhh_np3tyCJ8x7h_oY1zc,
  router_Ncv425wa6JVKOrpunRRYzdWAFGPi6j3V0E5sQIXIAws,
  payload_client_9awFqeJfOxWWw9P9gGujmOhoruIPaxuOyIqhMnDZJso,
  navigation_repaint_client_crtsTcArahX_z_mIy4A7Wd8mH5QPtOBGvuhy9a5FzTw,
  check_outdated_build_client__x4rjp3_mFzRiX8bUTRJ9V7nkPvr6qr623vfBzwfh0M,
  chunk_reload_client_ipyFGoV_cc2tKN5Ft76XgDCSH58ohXXoBt5M0Bihqeg,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_fWxD_a37zqMHE4pQP2oj8LX09X_dzK1VQioQlxMSSQg,
  sentry_client_lI2TwdvWlaucy2xLmAW4iRSp8oey_G5EUAq60bdPyDY,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  switch_locale_path_ssr_fnxa2RYAY_peo_MMkQ7_VG1NhrAK_PVtbTTHX6LELzw,
  route_locale_detect_W8L_3FWZffxQXyYzjnU2y6T1XqXg4c9TA_N6fHHyIsc,
  i18n_LsSaASFS02kZq87HHqjlArgumkYBqMEtIreF9ajiw4A,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  hasFeature_I9mRgyaoLrM3oQj4QrQUzBYhMbVNM2ZpgvIZP2OsjFY,
  ssg_detect_2DbCXkmMHx4N4OEdBb6zRiYZPfVasIxCqYjroZKZU1Y
]