<script setup lang="ts">
import type { NuxtError } from "#app"

defineProps<{ error: NuxtError }>()

const handleError = () => clearError({ redirect: "/" })
</script>
<template>
  <NuxtLayout name="unauthenticated">
    <template #heading>
      <TypographyHeading :level="3">
        {{ $t("error.title") }}
      </TypographyHeading>
      <p class="mt-1 text-sm/5 text-slate-600">
        {{ $t("error.text") }}
      </p>
    </template>
    <div class="px-6 sm:p-10">
      <LocalisedAlert :error />
      <FormButton variant="black" class="mt-4 w-full" @click="handleError">
        {{ $t("error.button") }}
      </FormButton>
    </div>
  </NuxtLayout>
</template>
