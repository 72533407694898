export function useDeveloperTools() {
  const devState = useState<{
    loading: boolean
    empty: boolean
    loadingDelay: number
    expanded: boolean
    hidden: boolean
  }>("devState", () => ({
    loading: false,
    empty: false,
    loadingDelay: 0,
    expanded: false,
    hidden: false,
  }))
  return { devState }
}
