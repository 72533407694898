<script setup lang="ts">
const devText = import.meta.dev ? "[DEV] " : ""
useHead({
  bodyAttrs: {
    class: "relative min-h-svh w-full",
  },
  htmlAttrs: {
    class: "relative min-h-svh w-full",
  },
  titleTemplate: (titleChunk) => {
    return devText + (titleChunk ? `${titleChunk} – Moxso` : "Moxso")
  },
  link: [{ rel: "icon", type: "image/ico", href: "/favicon.ico" }],
  meta: [
    { name: "referrer", content: "no-referrer-when-downgrade" },
    { name: "robots", content: "noindex, nofollow" },
  ],
})
</script>
<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
